import { FieldTaskStartOption, type ParametrizationFragment } from "@/api/sdk";
import { LoadingContent } from "@/components/LoadingContent";
import {
  useFieldTaskTypesTransformedQuery,
  useFieldTaskTypesWithDefaultsTransformedQuery,
} from "@/field-task/useFieldTaskTypesTransformedQuery";
import { Button } from "@roboton/ui";
import clsx from "clsx";
import type { ReactNode } from "react";
import { Controller, type SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GroupAndTaskFieldset, type GroupAndTaskFieldsetData } from "./create-task/GroupAndTaskFieldset";
import { ParametrizationFieldset, type ParametrizationFieldsetData } from "./create-task/ParametrizationFieldset";
import { StartFieldset, type StartFieldsetData } from "./create-task/StartFieldset";

type EntityData = { id: string; name: string } & (
  | { entityType: "growingPlan"; selectedRows?: number[] }
  | { entityType: "block" }
);

type FormData = StartFieldsetData & {
  procedures: {
    entity: EntityData;
    operation: GroupAndTaskFieldsetData;
    parametrization?: ParametrizationFieldsetData;
  }[];
};

type CreateNewTaskFormProps = {
  entities: EntityData[];
  defaultStartValues?: Partial<StartFieldsetData>;
  className?: string;
  onSuccessSubmit: SubmitHandler<FormData>;
  onCancel: () => void;
};

export const CreateNewTaskForm = ({
  className,
  defaultStartValues,
  entities,
  onSuccessSubmit,
  onCancel,
}: CreateNewTaskFormProps) => {
  const { t } = useTranslation();

  const form = useForm<FormData>({
    shouldUseNativeValidation: false,
    defaultValues: {
      startOption: FieldTaskStartOption.AsSoonAsPossible,
      ...defaultStartValues,
      // required step to initialize the procedures array
      procedures: entities.map((entity) => ({
        entity,
      })),
    },
  });

  const { handleSubmit, watch, control } = form;
  const { fields } = useFieldArray({ control, name: "procedures" });

  const procedures = watch("procedures");

  return (
    <form className={clsx("flex flex-col gap-4", className)} onSubmit={handleSubmit(onSuccessSubmit)}>
      <StartFieldset control={control} />
      <div className={"flex flex-col divide-light-50 divide-y"}>
        {fields.map((item, index) => {
          return (
            <FieldTaskTypeRemoteData key={item.id} id={item.entity.id} entityType={item.entity.entityType}>
              {({ isPending, data }) => {
                const hasData = data.length > 0;

                const defaultGroupValue = data?.[0]?.value;
                const defaultTypeValue = data?.[0]?.types?.[0].value;

                const taskTypeValue = procedures[index].operation?.taskType || defaultTypeValue;
                const groupValue = procedures[index].operation?.group || defaultGroupValue;

                const parametrizations = data
                  .find((group) => group.value === groupValue)
                  ?.types.find((type) => type.value === taskTypeValue)?.parametrization;

                return (
                  <div className={"p-4 bg-light-25 relative"}>
                    {hasData ? (
                      <>
                        <div className={"typo-h4"}>{item.entity.name}</div>
                        <Controller
                          control={control}
                          name={`procedures.${index}.operation`}
                          defaultValue={{ group: defaultGroupValue, taskType: defaultTypeValue }}
                          render={({ field: { value, onChange } }) => (
                            <GroupAndTaskFieldset groups={data} value={value} onChange={onChange} />
                          )}
                        />
                        {parametrizations?.length ? (
                          <ParametrizationFieldset
                            name={`procedures.${index}.parametrization`}
                            control={control}
                            parametrizations={parametrizations}
                            keyPrefix={`${item.id}.${taskTypeValue}`}
                          />
                        ) : null}
                      </>
                    ) : isPending ? (
                      <LoadingContent className={"bg-light-25 rounded-inherit absolute inset-0 bg-opacity-70 p-4"} />
                    ) : (
                      <>{t("No data")}</>
                    )}
                  </div>
                );
              }}
            </FieldTaskTypeRemoteData>
          );
        })}
      </div>

      <div className={"flex flex-wrap gap-2 self-end"}>
        <Button type={"reset"} variant={"primary-negative"} onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button icon={"check"}>{t("Create the Task")}</Button>
      </div>
    </form>
  );
};

const FieldTaskTypeRemoteData = ({
  id,
  entityType,
  children,
}: {
  id: string;
  entityType: "growingPlan" | "block";
  children: ({
    isPending,
    data,
  }: {
    isPending: boolean;
    data: {
      label: string;
      value: string;
      types: {
        value: string;
        label: string;
        groupCode: string;
        parametrization: ParametrizationFragment[];
      }[];
    }[];
  }) => ReactNode;
}) => {
  const resolvedId = entityType === "growingPlan" ? id : undefined;
  const useFieldTaskTypes = resolvedId
    ? useFieldTaskTypesWithDefaultsTransformedQuery
    : useFieldTaskTypesTransformedQuery;

  const query = useFieldTaskTypes(
    { availableJustForBlock: entityType === "block", growingPlanId: resolvedId },
    { enabled: !!resolvedId },
  );

  const isBlockTask = entityType === "block";

  const { fieldTaskGroups, fieldTaskTypes } = query?.data || {};

  const types =
    fieldTaskTypes?.flatMap((type) => {
      if (isBlockTask && !type.availableForBlock) return [];
      return [
        {
          value: type.type,
          label: type.commonName,
          groupCode: type.group.code,
          parametrization: type.parametrization,
        },
      ];
    }) || [];

  const mappedGroups =
    (fieldTaskGroups || []).map((group) => ({
      value: group.code,
      label: group.commonName,
      types: types.filter(({ groupCode }) => groupCode === group.code),
    })) || [];

  return (
    <>
      {children({
        isPending: query?.isPending,
        data: mappedGroups,
      })}
    </>
  );
};
