import { type FieldTaskParametrization, FieldTaskParametrizationCode } from "@/api/sdk";
import { logger } from "@/logger";
import { exhaustiveMatchingGuard } from "@roboton/tools";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useLocalizeFieldTaskParametrizationCode() {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      localizeFieldTask: (taskType: Pick<FieldTaskParametrization, "code" | "unit">) => {
        const { code, unit } = taskType;
        const _code = code as FieldTaskParametrizationCode;
        switch (_code) {
          case FieldTaskParametrizationCode.AmountOfWater:
            return t("Amount of water per plant (in {{ unit }})", { unit });
          case FieldTaskParametrizationCode.AmountOfWaterMm:
            return t("Amount of water per plant (in {{ unit }})", { unit });
          case FieldTaskParametrizationCode.BreakupLiftOffset:
            return t("Breakup lift offset (in {{ unit }})", { unit });
          case FieldTaskParametrizationCode.BreakupRotorSpeed:
            return t("Breakup rotor speed (in {{ unit }})", { unit });
          case FieldTaskParametrizationCode.SurfaceIrrigationLiftOffset:
            return t("Surface irrigation lift offset (in {{ unit }})", { unit });
          case FieldTaskParametrizationCode.SurfaceIrrigationRows:
            return t("Surface irrigation rows (in {{ unit }})", { unit });
          default:
            exhaustiveMatchingGuard(_code);
            logger.warn(`Unknown parametrization code: ${code}`);
            return code;
        }
      },
    }),
    [t],
  );
}
