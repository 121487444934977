import {
  type FieldTaskTypesQuery,
  type FieldTaskTypesWithDefaultValuesQuery,
  useFieldTaskTypesQuery,
  useFieldTaskTypesWithDefaultValuesQuery,
} from "@/api/sdk";
import { keepPreviousData } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";

function transform<T extends FieldTaskTypesQuery>({ fieldTaskTypes, ...data }: T, isForBlock: boolean) {
  const filteredTypes = fieldTaskTypes.filter((fieldTaskType) => {
    return isForBlock ? fieldTaskType.availableForBlock : true;
  });

  // get all unique groups
  const uniqueGroups = filteredTypes.reduce((acc: (typeof filteredTypes)[number]["group"][], fieldTaskType) => {
    if (!acc.some((group) => group.code === fieldTaskType.group.code)) {
      acc.push(fieldTaskType.group);
    }
    return acc;
  }, []);

  // get all unique parametrization types
  const uniqueParametrization = [...new Set(filteredTypes.flatMap((fieldTaskType) => fieldTaskType.parametrization))];

  return {
    ...data,
    parametrization: uniqueParametrization,
    fieldTaskGroups: uniqueGroups,
    fieldTaskTypes: filteredTypes,
  };
}

/**
 * Get unique field task groups and add them to the API response
 */
export const useFieldTaskTypesTransformedQuery = (
  variables: {
    availableJustForBlock: boolean;
  },
  options?: Parameters<typeof useFieldTaskTypesQuery>[1] & { select?: never },
) => {
  return useFieldTaskTypesQuery(undefined, {
    ...options,
    select: (data) => transform(data, variables.availableJustForBlock),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(5),
  });
};

// Same as above but with default values for the parametrization, min, max, step etc.
// The default values might depend on the Growing Plan ID.
// In most cases, we don't need it and we can use the above hook which is cached .
export const useFieldTaskTypesWithDefaultsTransformedQuery = (
  variables: {
    availableJustForBlock: boolean;
    growingPlanId?: string;
  },
  options?: Parameters<typeof useFieldTaskTypesWithDefaultValuesQuery>[1] & { select?: never },
) => {
  return useFieldTaskTypesWithDefaultValuesQuery(
    {
      growingPlanId: variables.growingPlanId,
    },
    {
      ...options,
      select: (data) => transform<FieldTaskTypesWithDefaultValuesQuery>(data, variables.availableJustForBlock),
    },
  );
};

export type FieldTaskTypesTransformedQuery = ReturnType<typeof transform>;
