import {
  type FieldTaskParametrization,
  type FieldTaskParametrizationCode,
  FieldTaskParametrizationType,
  type ParametrizationFragment,
} from "@/api/sdk";
import { RowsField } from "@/field-task/components/create-task/RowsField";
import { useLocalizeFieldTaskParametrizationCode } from "@/field-task/components/utils";
import { mapRemoteDefaultParametrizationValueToNumber } from "@/utils/mapRemoteDefaultParametrizationValueToNumber";
import { FormField, Input, Toggle } from "@roboton/ui";
import type { ChangeEvent, ComponentProps } from "react";
import { type Control, Controller, type Path, type PathValue } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";
import { useTranslation } from "react-i18next";

type ParametrizationFieldsetData = Record<FieldTaskParametrizationCode | string, string | number>;

const mapRemoteParametrizationToValueType = (parametrization: FieldTaskParametrization) => {
  switch (parametrization.type) {
    case FieldTaskParametrizationType.Bool:
      return "boolean" as const;
    case FieldTaskParametrizationType.Float:
    case FieldTaskParametrizationType.Int:
      return "number" as const;
    case FieldTaskParametrizationType.IntList:
      return "rows" as const;
    default:
      return "string" as const;
  }
};

const ParametrizationFieldset = <T extends FieldValues = FieldValues>({
  name,
  control,
  keyPrefix,
  presetRows,
  parametrizations,
}: {
  name: Path<T>;
  control: Control<T>;
  parametrizations: ParametrizationFragment[];
  presetRows?: number[];
  keyPrefix: string;
}) => {
  const { t } = useTranslation();
  const { localizeFieldTask } = useLocalizeFieldTaskParametrizationCode();

  const dynamicFields = parametrizations.map((parametrization) => {
    const kind = mapRemoteParametrizationToValueType(parametrization);
    const defaultValueNumberArray = mapRemoteDefaultParametrizationValueToNumber(parametrization.default);
    return {
      label: localizeFieldTask(parametrization),
      kind,
      fieldProps: {
        name: `${name}.${parametrization.code}` as Path<T>,
        id: `${keyPrefix}.${parametrization.code}`,
        defaultValue: (kind === "rows" ? defaultValueNumberArray.join(",") : defaultValueNumberArray?.[0]) as PathValue<
          T,
          Path<T>
        >,
      },
      ...parametrization,
    };
  });

  return (
    <fieldset className={"grid gap-4 sm:grid-cols-2"}>
      <legend>{t("Parametrization")}</legend>

      {dynamicFields?.map(({ kind, fieldProps, ...dynamicField }) => {
        const key = `${keyPrefix}.${dynamicField.code}`;

        const minMaxValidationMessage = t("The value must be between {{ min }} and {{ max }}", {
          min: dynamicField.min,
          max: dynamicField.max,
        });

        return (
          <Controller
            key={key}
            name={fieldProps.name}
            control={control}
            defaultValue={fieldProps.defaultValue}
            rules={{
              required: kind === "boolean" ? false : t("This field is required"),
              min:
                typeof dynamicField.min === "number"
                  ? { value: dynamicField.min, message: minMaxValidationMessage }
                  : undefined,
              max:
                typeof dynamicField.max === "number"
                  ? { value: dynamicField.max, message: minMaxValidationMessage }
                  : undefined,
            }}
            shouldUnregister
            render={({ field, fieldState }) => {
              const { error } = fieldState;
              const errorMessage = error ? error.message : undefined;

              if (kind === "rows") {
                const handleRowsChange: ComponentProps<typeof RowsField>["onChange"] = (rows) => {
                  field.onChange(rows.join(","));
                };
                return (
                  <RowsField
                    className={"col-span-full"}
                    keyPrefix={keyPrefix}
                    presetValues={presetRows}
                    onChange={handleRowsChange}
                    customOptionError={errorMessage}
                  />
                );
              }

              if (kind === "boolean") {
                return (
                  <FormField
                    messages={
                      errorMessage ? [{ key: errorMessage, variant: "alert", children: errorMessage }] : undefined
                    }
                  >
                    <Toggle
                      id={fieldProps.id}
                      label={dynamicField.label}
                      {...field}
                      state={error ? "negative" : undefined}
                    />
                  </FormField>
                );
              }

              const handleInputChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
                field.onChange(kind === "number" ? Number.parseFloat(value) : value);
              };
              return (
                <FormField
                  messages={
                    errorMessage ? [{ key: errorMessage, variant: "alert", children: errorMessage }] : undefined
                  }
                >
                  <Input
                    {...field}
                    onChange={handleInputChange}
                    id={fieldProps.id}
                    label={dynamicField.label}
                    type={kind === "number" ? "number" : "text"}
                    min={dynamicField.min ?? undefined}
                    max={dynamicField.max ?? undefined}
                    step={dynamicField.step ?? undefined}
                    state={error ? "negative" : undefined}
                  />
                </FormField>
              );
            }}
          />
        );
      })}
    </fieldset>
  );
};

export { ParametrizationFieldset, type ParametrizationFieldsetData };
