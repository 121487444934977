import { type FieldTaskInput, FieldTaskParametrizationType } from "@/api/sdk";
import { scalarUtils } from "@/utils/scalars";
import type { ComponentProps } from "react";
import type { CreateNewTaskForm } from "./components/CreateNewTaskForm";

const transformNewTaskFormDataToFieldTaskInput = (
  data: Parameters<ComponentProps<typeof CreateNewTaskForm>["onSuccessSubmit"]>[0],
  parametrization: { code: string; type: FieldTaskParametrizationType }[],
): FieldTaskInput => {
  const startAtISOString =
    data.startAtDate && data.startAtTime ? new Date(`${data.startAtDate}T${data.startAtTime}`).toISOString() : null;

  return {
    procedures: data.procedures.map((procedure) => ({
      growingPlanId: procedure.entity.entityType === "growingPlan" ? procedure.entity.id : null,
      blockId: procedure.entity.entityType === "block" ? procedure.entity.id : null,
      type: procedure.operation.taskType,
      parametrization: procedure.parametrization
        ? Object.entries(procedure.parametrization).map(([code, value]) => {
            const type = parametrization?.find((param) => param.code === code)?.type;
            return {
              code,
              floatValue: typeof value === "number" && type === FieldTaskParametrizationType.Float ? value : null,
              intValue: typeof value === "number" && type === FieldTaskParametrizationType.Int ? value : null,
              stringValue: typeof value === "string" && type === FieldTaskParametrizationType.String ? value : null,
              intListValue:
                typeof value === "string" && type === FieldTaskParametrizationType.IntList
                  ? value
                      // remove empty strings and parse to number
                      .split(",")
                      .map((v) => Number(v.trim()))
                      .filter((v) => !Number.isNaN(v))
                  : null,
            };
          })
        : null,
    })),

    start: {
      startOption: data.startOption,
      startAtTime: startAtISOString ? scalarUtils.toTime(startAtISOString) : null,
      startAtDate: startAtISOString ? scalarUtils.toDate(startAtISOString) : null,
    },
  };
};

export const fieldTaskUtils = {
  transformNewTaskFormDataToFieldTaskInput,
};
