import { segmentIndexToNameMap } from "@/growing-plan/growing-plan-detail/plant-spacing/utils";
import { Checkbox, FormField, Radio } from "@roboton/ui";
import clsx from "clsx";
import { type ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export const RowsField = ({
  className,
  keyPrefix,
  presetValues,
  customOptionError,
  onChange,
}: {
  className?: string;
  keyPrefix: string;
  presetValues?: number[];
  customOptionError?: string;
  onChange: (rows: number[]) => void;
}) => {
  const { t } = useTranslation();
  const [customValues, setCustomValues] = useState<number[]>([]);
  const [option, setOption] = useState<"preset" | "custom">("custom");
  const isCustomValuesDisabled = option === "preset";

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as "preset" | "custom";
    setOption(value);
    onChange(presetValues && value === "preset" ? presetValues : customValues);
  };

  const handleCustomRowsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(e.target.value);
    const rows = e.target.checked ? [...customValues, newValue] : customValues.filter((row) => row !== newValue);
    setCustomValues(rows);
    onChange(rows);
  };

  return (
    <div className={clsx("sm:grid gap-x-4 sm:grid-cols-2", className)}>
      <div className={"col-span-full"}>{t("Used rows")}</div>

      <div>
        <Radio
          label={t("Use the Growing Plan Setting")}
          name={`${keyPrefix}-rowsOption`}
          id={`${keyPrefix}-preset-option`}
          value={"preset"}
          checked={option === "preset"}
          disabled={!presetValues?.length}
          onChange={handleOptionChange}
        />
        <div className={isCustomValuesDisabled ? "" : "text-light-100"}>
          {presetValues ? presetValues.join(", ") : t("No preset values")}
        </div>
      </div>

      <FormField
        messages={
          customOptionError ? [{ key: customOptionError, variant: "alert", children: customOptionError }] : undefined
        }
      >
        <Radio
          name={`${keyPrefix}-rowsOption`}
          id={`${keyPrefix}-custom-option`}
          label={t("Set up manually")}
          value={"custom"}
          checked={option === "custom"}
          onChange={handleOptionChange}
          state={customOptionError ? "negative" : undefined}
        />

        <div className={"flex flex-wrap gap-x-4 gap-y-2"}>
          {segmentIndexToNameMap.map((label, index) => {
            const id = `${keyPrefix}-segment-${label}`;
            return (
              <Checkbox
                key={`${keyPrefix}-segment-${label}`}
                id={id}
                label={label}
                value={index}
                checked={customValues.includes(index)}
                onChange={handleCustomRowsChange}
                disabled={isCustomValuesDisabled}
                state={customOptionError ? "negative" : undefined}
              />
            );
          })}
        </div>
      </FormField>
    </div>
  );
};
