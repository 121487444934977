import { useCallback, useState } from "react";

// Inspired by https://daveteu.medium.com/react-custom-confirmation-box-458cceba3f7b

type ConfirmResolver = (value: boolean) => void;

const createPromise = (): [Promise<boolean>, ConfirmResolver] => {
  let resolver: ConfirmResolver = () => {};
  const promise = new Promise<boolean>((resolve) => {
    resolver = resolve;
  });
  return [promise, resolver];
};

export const useConfirm = <TState>(options?: {
  initialState?: TState;
  /* default: false */
  keepStateOnClose?: boolean;
}): {
  state?: TState;
  isOpen: boolean;
  getConfirmation: (state?: TState) => Promise<boolean>;
  onDecline: () => void;
  onConfirm: () => void;
} => {
  const { initialState, keepStateOnClose } = options || {};
  const [state, seState] = useState(initialState);

  const [isOpen, setIsOpen] = useState(false);
  const [resolver, setResolver] = useState<ConfirmResolver>(() => () => {});

  const getConfirmation = useCallback(async (state?: TState): Promise<boolean> => {
    seState(state);
    setIsOpen(true);
    const [promise, resolve] = createPromise();
    setResolver(() => resolve);
    return promise;
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (!keepStateOnClose) {
      seState(undefined);
    }
  }, [keepStateOnClose]);

  const onConfirm = useCallback(() => {
    resolver(true);
    closeModal();
  }, [resolver, closeModal]);

  const onDecline = useCallback(() => {
    resolver(false);
    closeModal();
  }, [resolver, closeModal]);

  return {
    state,
    isOpen,
    getConfirmation,
    onDecline,
    onConfirm,
  };
};
