import type { FieldTaskCardFragment } from "@/api/sdk";
import { FieldTaskState } from "@/api/sdk";
import { Card } from "@/components/Card";
import { LoadingContent } from "@/components/LoadingContent";
import { generatePath, routes } from "@/routes";
import { backToUtils } from "@/utils/backToUtils";
import { formatDateTime, formatDurationToHumanize } from "@/utils/format";
import { useTranslationEnums } from "@/utils/useTranslationEnums";
import { Button, Tag } from "@roboton/ui";
import clsx from "clsx";
import type { ComponentProps, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { WarningMessage } from "./WarningMessage";

const taskTypeToTagColorMap = {
  [FieldTaskState.Aborted]: "red",
  [FieldTaskState.Completed]: "green",
  [FieldTaskState.Created]: "gray",
  [FieldTaskState.Expired]: "red",
  [FieldTaskState.Failed]: "red",
  [FieldTaskState.Invalid]: "red",
  [FieldTaskState.InProgress]: "yellow",
  [FieldTaskState.PartiallyCompleted]: "yellow",
  [FieldTaskState.Scheduled]: "blue",
  [FieldTaskState.WaitingForRouteProcessing]: "gray",
} satisfies Record<FieldTaskState, ComponentProps<typeof Tag>["color"]>;

export const FieldTaskCard = ({
  task,
  taskDetailTo,
  variant,
  isPending,
  onDeleteClick,
  onStartClick,
  onMoveUpClick,
  onMoveDownClick,
}: {
  taskDetailTo?: string;
  task: FieldTaskCardFragment & {
    typeCommonName?: string;
    groupCommonName?: string;
  };
  variant: "robot" | "growingPlan";
  isPending?: boolean;
  onDeleteClick: (id: string) => void;
  onStartClick?: (id: string) => void;
  onMoveUpClick?: (id: string) => void;
  onMoveDownClick?: (id: string) => void;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { tEnums } = useTranslationEnums();

  const {
    moveDown: isMoveableDown,
    moveUp: isMoveableUp,
    delete: isDeletable,
    startNow: isStartable,
  } = task.actionsPermissions;

  return (
    <Card key={task.id} className={"relative flex flex-col gap-2"}>
      {isPending ? <LoadingContent className={"bg-light-25 absolute inset-0 bg-opacity-70"} /> : null}

      {/* header */}
      {task.procedures.map((procedure, index) => (
        // The procedure doesn't have a unique identifier, so we use the index as a key
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <div key={index} className={"flex flex-wrap items-start justify-between"}>
          <div>
            {procedure.type ? <h3 className={"typo-h3"}>{task.typeCommonName}</h3> : null}

            <div>
              {procedure.type ? <span className={"typo-sm"}>{task.groupCommonName}</span> : null}
              {variant === "growingPlan" && procedure.block ? (
                <>
                  {" • "}
                  <Button
                    variant={"text-base"}
                    as={Link}
                    to={generatePath(routes.BlockDetail, { id: procedure.block.field.id, blockId: procedure.block.id })}
                    icon="arrow_right"
                    iconAlign={"right"}
                    title={t("Go to the Growing Plan")}
                  >
                    {procedure.block.name}
                  </Button>
                </>
              ) : variant === "growingPlan" && procedure.growingPlan ? (
                <>
                  {" • "}
                  <Button
                    variant={"text-base"}
                    as={Link}
                    to={generatePath(routes.RobotDetail, { id: task.robotAssignment.id })}
                    icon="arrow_right"
                    iconAlign={"right"}
                    title={t("Go to the Robot Detail")}
                  >
                    {task.robotAssignment.name}
                  </Button>
                </>
              ) : variant === "robot" && procedure.growingPlan ? (
                <>
                  {" • "}
                  <Button
                    variant={"text-base"}
                    as={Link}
                    to={generatePath(routes.GrowingPlanDetailGeneral, { id: procedure.growingPlan.id })}
                    icon="arrow_right"
                    iconAlign={"right"}
                    title={t("Go to the Growing Plan")}
                  >
                    {procedure.growingPlan.name}
                  </Button>
                </>
              ) : null}
            </div>
          </div>

          <div className={"flex shrink-0 gap-2"}>
            {task.numberOfErrorWorkTasks ? (
              <Tag variant={"filled"} size={"small"} color={"red"}>
                {t("errorWithCount", { count: task.numberOfErrorWorkTasks })}
              </Tag>
            ) : null}
            <Tag variant={"stroked"} size={"small"} color={taskTypeToTagColorMap[task.state]}>
              {tEnums("fieldTaskState", task.state)}
            </Tag>
          </div>
        </div>
      ))}

      {/* body */}
      <div className={"flex flex-col gap-2"}>
        <div className={"grid gap-[inherit] sm:grid-cols-2"}>
          {task.startAt ? <Item label={t("Planned start")}>{formatDateTime(task.startAt, language)}</Item> : null}
          {task.completionAt ? (
            <Item label={t("Planned completition")}>{formatDateTime(task.completionAt, language)}</Item>
          ) : null}

          {task.delay ? (
            <WarningMessage className={"col-span-full"}>
              Delay: <WarningMessage className={"col-span-full"}>{formatDurationToHumanize(task.delay)}</WarningMessage>
            </WarningMessage>
          ) : null}

          {task.estimatedStartAt ? (
            <Item label={t("Estimated start")}>{formatDateTime(task.estimatedStartAt, language)}</Item>
          ) : null}
          {task.estimatedCompletionAt ? (
            <Item label={t("Estimated completion")}>{formatDateTime(task.estimatedCompletionAt, language)}</Item>
          ) : null}
          {task.startedAt ? <Item label={t("Real start")}>{formatDateTime(task.startedAt, language)}</Item> : null}
          {task.completedAt ? (
            <Item label={t("Real completion")}>{formatDateTime(task.completedAt, language)}</Item>
          ) : null}
        </div>

        <div className={"grid gap-[inherit] sm:grid-cols-2"}>
          {typeof task.estimatedPercentagePowerConsumption === "number" ? (
            <Item label={t("Expected battery power consumption")}>
              {task.estimatedPercentagePowerConsumption.toFixed(2)} %
            </Item>
          ) : null}
          {typeof task.estimatedWaterConsumption === "number" ? (
            <Item label={t("Estimated water consumption")}>{task.estimatedWaterConsumption.toFixed(2)} l</Item>
          ) : null}
          {typeof task.estimatedTimeConsumption === "number" ? (
            <Item label={t("Estimated time consumption")}>
              {formatDurationToHumanize(task.estimatedTimeConsumption)}
            </Item>
          ) : null}
        </div>
      </div>

      {/* footer */}
      <div className={"flex flex-wrap items-center gap-2"}>
        <Button
          type={"button"}
          size={"small"}
          variant={"primary-negative"}
          aria-label={"Delete Task"}
          icon={"trash"}
          disabled={!isDeletable}
          onClick={() => onDeleteClick(task.id)}
        >
          Delete
        </Button>

        {onStartClick ? (
          <Button
            type={"button"}
            size={"small"}
            variant={"primary-positive"}
            aria-label={"Start Task Now"}
            disabled={!isStartable}
            onClick={() => onStartClick(task.id)}
          >
            Start now
          </Button>
        ) : null}

        {onMoveDownClick ? (
          <Button
            type={"button"}
            size={"small"}
            variant={"primary-positive"}
            aria-label={"Move Task Down"}
            disabled={!isMoveableDown}
            onClick={() => onMoveDownClick(task.id)}
          >
            <span>↓</span> Move down
          </Button>
        ) : null}

        {onMoveUpClick ? (
          <Button
            type={"button"}
            size={"small"}
            variant={"primary-positive"}
            aria-label={"Move Task Up"}
            disabled={!isMoveableUp}
            onClick={() => onMoveUpClick(task.id)}
          >
            <span>↑</span> Move up
          </Button>
        ) : null}

        {taskDetailTo ? (
          <Button
            as={Link}
            to={taskDetailTo}
            onClick={() => backToUtils.set()}
            size={"small"}
            className={"ml-auto"}
            icon={"arrow_right"}
            iconAlign={"right"}
          >
            Detail
          </Button>
        ) : null}
      </div>
    </Card>
  );
};

const Item = ({ label, className, children, ...rest }: JSX.IntrinsicElements["div"] & { label: ReactNode }) => {
  return (
    <div {...rest} className={clsx("text-sm", className)}>
      <div className={"font-bold"}>{label}</div>
      {children}
    </div>
  );
};
