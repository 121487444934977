import type { DefaultParametrizationValue } from "@/api/sdk";

export function mapRemoteDefaultParametrizationValueToNumber(value: DefaultParametrizationValue | null) {
  if (value?.__typename === "FloatValue") {
    return [value.floatValue];
  }

  if (value?.__typename === "IntListValue") {
    return value.value;
  }

  return [];
}
