import { FieldTaskStartOption, useCreateFieldTaskMutation, useGrowingPlanTasksQuery } from "@/api/sdk";
import { DateForm } from "@/components/DateForm/DateForm";
import { useDateForm } from "@/components/DateForm/useDateForm";
import { LoadingContent } from "@/components/LoadingContent";
import { Pagination, usePaginationState } from "@/components/Pagination";
import { PlaceholderCard } from "@/components/PlaceholderCard";
import type { CreateNewTaskForm } from "@/field-task/components/CreateNewTaskForm";
import { CreateNewTaskModal } from "@/field-task/components/CreateNewTaskModal";
import { FieldTasksList } from "@/field-task/components/FieldTasksList";
import { useFieldTaskTypesTransformedQuery } from "@/field-task/useFieldTaskTypesTransformedQuery";
import { fieldTaskUtils } from "@/field-task/utils";
import { GrowingPlanUtils } from "@/growing-plan/utils";
import { notify } from "@/utils/Notifications";
import { scalarUtils } from "@/utils/scalars";
import { useConfirm } from "@/utils/useConfirm";
import { useQueryRefetch } from "@/utils/useQueryRefetch";
import { Button, Message } from "@roboton/ui";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useGrowingPlanDetailLayoutContext } from "./GrowingPlanDetailLayout";

export const GrowingPlanDetailRobotTasksPage = () => {
  const { t } = useTranslation();
  const {
    remoteGrowingPlan: { id: growingPlanId, state, field, name: growingPlanName },
  } = useGrowingPlanDetailLayoutContext();

  const [page, setPage] = usePaginationState();

  const { handleSubmit: handleDateFormSuccessSubmit, startAt } = useDateForm();

  const {
    data: { fieldTaskTypes, parametrization = [] } = {},
    isLoading: isLoadingFieldTaskTypes,
  } = useFieldTaskTypesTransformedQuery({ availableJustForBlock: false });

  const {
    data: { tasks } = {},
    refetch: refetchTasks,
    isLoading: isLoadingTasks,
  } = useGrowingPlanTasksQuery({
    startAt,
    growingPlanId,
    paginationInput: { page, perPage: 12 },
  });

  const isLoading = isLoadingTasks || isLoadingFieldTaskTypes;

  const { isRefetching, handleRefetch } = useQueryRefetch(refetchTasks);
  const { mutate: createTaskMutate } = useCreateFieldTaskMutation({
    onSuccess: () => {
      onCreateTaskModalConfirm();
      notify.positive(t("Task created successfully"));
      handleRefetch();
    },
  });

  const lastPage = tasks?.pageInfo?.lastPage || page;
  const hasTasks = !!tasks && !!tasks?.nodes.length;

  const handleCreateNewTaskFormSuccessSubmit: ComponentProps<typeof CreateNewTaskForm>["onSuccessSubmit"] = (data) => {
    createTaskMutate({
      fieldTaskInput: fieldTaskUtils.transformNewTaskFormDataToFieldTaskInput(data, parametrization),
    });
  };

  const {
    state: createTaskModalState,
    isOpen: isCreateTaskModalOpen,
    onConfirm: onCreateTaskModalConfirm,
    onDecline: onCreateTaskModalDecline,
    getConfirmation: getCreateTaskModalConfirmation,
  } = useConfirm<ComponentProps<typeof CreateNewTaskModal>["defaultValues"]>();

  const handleCreateTaskClick = () => getCreateTaskModalConfirmation();
  const handleCreateTaskForSpecificDateClick: ComponentProps<typeof FieldTasksList>["onClickCreateTask"] = (date) =>
    getCreateTaskModalConfirmation({
      startAtDate: scalarUtils.toDate(date),
      startOption: FieldTaskStartOption.ScheduleStart,
    });

  const canCreateTask = GrowingPlanUtils.canCreateTask({ state, field });

  return (
    <>
      <h2 className={"sr-only"}>{t("Robot Tasks")}</h2>

      <CreateNewTaskModal
        defaultValues={createTaskModalState}
        entities={[{ entityType: "growingPlan", id: growingPlanId, name: growingPlanName }]}
        isOpen={isCreateTaskModalOpen}
        onClose={onCreateTaskModalDecline}
        onSuccessSubmit={handleCreateNewTaskFormSuccessSubmit}
      />

      <div className={"flex flex-col flex-wrap items-center gap-4 sm:flex-row sm:items-start sm:justify-between"}>
        <DateForm
          label={t("Showing from")}
          defaultValue={startAt}
          disabled={isLoading}
          onSuccessSubmit={handleDateFormSuccessSubmit}
        />

        <Button type={"button"} icon={"plus"} onClick={handleCreateTaskClick} disabled={!canCreateTask}>
          {t("Create a new Task")}
        </Button>
        {canCreateTask ? null : (
          <Message color={"yellow"} className={"ml-auto"}>
            {t("New task can be created only when the Growing Plan is in progress.")}
          </Message>
        )}
      </div>

      {isLoading ? (
        <LoadingContent className={"grow"} />
      ) : !hasTasks || !fieldTaskTypes ? (
        <PlaceholderCard className={"mt-4"} icon={"information"} description={t("There are no Tasks.")} />
      ) : (
        <>
          <FieldTasksList
            className={"mt-8"}
            tasks={tasks}
            fieldTaskTypes={fieldTaskTypes}
            variant={"growingPlan"}
            allowExtendedActions={false}
            isPending={isRefetching}
            onClickCreateTask={handleCreateTaskForSpecificDateClick}
          />
          <Pagination className={"mx-auto mt-4"} currentPage={page} pageCount={lastPage} onClick={setPage} />
        </>
      )}
    </>
  );
};
